:root {
  --selected-border-color: #304ffe;
  --selected-background-color: #f2f5ff;
  --btn-primary-color: #c3b092;
}

.cardSelected {
  border: 2px solid var(--selected-border-color);
  background-color: var(--selected-background-color);
}

.card {
  min-width: 100% !important;
}

.btn-primary {
  background-color: var(--btn-primary-color) !important;
  border-color: var(--btn-primary-color) !important;
}
