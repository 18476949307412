.review-text {
	font-style: italic;
}

.service-tag {
	display: inline-block;
	font-size: 75%;
	line-height: 1;
    text-align: center;
    white-space: nowrap;
	border-bottom: 1px solid rgb(224, 230, 232);
	padding: 2px 5px 5px 2px;
	margin-right: 5px;
	border-radius: 3px;
}

.review-serviceslist-container {
	overflow-x: auto;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none; 
}

.review-serviceslist-container::-webkit-scrollbar {
	display: none;
	width: 0 !important;
}
