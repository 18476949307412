#map-modal-body {
  height: 80vh;
}

.marker-size {
  font-size: 20px;
}

.modal-close-btn {
  position: absolute;
  right: 10px;
  border: none;
  cursor: pointer;
  background: none;
  top: 5px;
  font-size: 30px;
}
.btn-close {
  display: none;
}
