.steps-action {
  /* height: 70px; */
  z-index: 100;
}
.adm-step-status-process {
  --icon-color: #c3b092;
}
.adm-step-icon-dot {
  color: #c3b092;
}
.adm-step-status-process .adm-step-title {
  color: #c3b092;
}
.border {
  border: 1px solid #c3b092 !important;
  /* max-width: 500px; */
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* width: 50%; */
}
.text-align-center {
  text-align: center;
}
