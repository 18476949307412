.avatar-group .avatar {
    position: relative;
    z-index: 2;
    border: 2px solid #fff;
}
.avatar {
    font-size: 1rem;
    display: inline-flex;
    width: 50;
    height: 50px;
    color: #fff;
    border-radius: .375rem;
    background-color: #adb5bd;
    align-items: center;
    /* justify-content: center; */
}
.avatar.rounded-circle img, .rounded-circle {
    border-radius: 50%!important;
}