.profile-card-bg {
  /* background: url('https://mk0promolandingy6g00.kinstacdn.com/wp-content/themes/tg/assets/images/templates/lp1/cover-image.svg') no-repeat center center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
}

.border-highlighted {
  border: 3 px solid rgba(55, 125, 255, 0.7) !important;
  box-shadow: 0 0 35px rgba(55, 125, 255, 0.125) !important;
}

.pic-profile-size {
  height: 100px;
  width: 100px;
}

.fullname-size {
  font-size: 1.6rem;
  margin-bottom: 0.2rem;
}

.placeholder-row-width {
  width: 80%;
}
.placeholderText {
  background-color: #e0e0e0;
  height: 16px;
  width: 100%;
  margin-bottom: 10px;
}
.text-primary {
  color: #c3b092 !important;
}
.badge-primary {
  background-color: #c3b092 !important;
}
.border-primary {
  border-color: #c3b092 !important;
}
.btn-outline-primary {
  color: #c3b092 !important;
  border-color: #c3b092 !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #c3b092 !important;
  border-color: #c3b092 !important;
}
